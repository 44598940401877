<template>
  <v-app id="inspire">
    <v-app-bar
      class="px-3"
      flat
      density="compact"
    >
      <v-avatar
        color="grey-darken-1"
        class="hidden-md-and-up"
        size="32"
      ></v-avatar>

      <v-spacer></v-spacer>

      <v-tabs
        centered
        color="grey-darken-2"
      >
        <v-tab
          v-for="link in links"
          :key="link"
          :text="link"
        ></v-tab>
      </v-tabs>
      <v-spacer></v-spacer>

      <v-avatar
        class="hidden-sm-and-down"
        color="grey-darken-1"
        size="32"
      ></v-avatar>
    </v-app-bar>

    <v-main class="bg-grey-lighten-3">
      <v-container>
        <v-row>
          <v-col
            cols="12"
            md="2"
          >
            <v-sheet
              rounded="lg"
              min-height="268"
            >
            토큰 : {{ token }}
              <!--  -->
            </v-sheet>
          </v-col>

          <v-col
            cols="12"
            md="8"
          >
            <v-sheet
              min-height="70vh"
              rounded="lg"
            >
              <v-btn @click="request()">알림허용</v-btn>
              <br>
              <a href="http://1.214.42.238:8080"><v-btn>HR</v-btn></a>
              <a href="http://1.214.42.238:8080/pop" target="_blank"><v-btn>POP</v-btn></a>
              <a href="http://1.214.42.238:8080/hrm" target="_blank"><v-btn>HRM</v-btn></a>
            <!-- <PopLot/> -->
              <!--  -->
              <br>
              버전 2
            </v-sheet>
          </v-col>

          <v-col
            cols="12"
            md="2"
          >
            <v-sheet
              rounded="lg"
              min-height="268"
            >
            메시지 : {{ msg }}
              <!--  -->
            </v-sheet>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script setup>
  const links = [
    'Dashboard',
    'Messages',
    'Profile',
    'Updates',
  ]
</script>

<script>
  // import PopLot from '@/components/PopLot.vue';

  export default {
    components: {
      // PopLot,
    },
    data: () => ({
      links: [
        'Dashboard',
        'Messages',
        'Profile',
        'Updates',
      ],
      token: '',
      msg: null,
    }),
    created() {
      this.token = window.sessionStorage.getItem('token');
    },
    methods: {
      request() {
        Notification.requestPermission();
      },
    }
  }
</script>