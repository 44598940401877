import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";

const config = {
  apiKey: "AIzaSyBx-v7xu9edaIRZ14NoIPNnaHo_Cs1_Mdo",
  authDomain: "fcm-front.firebaseapp.com",
  projectId: "fcm-front",
  storageBucket: "fcm-front.appspot.com",
  messagingSenderId: "619784594808",
  appId: "1:619784594808:web:8d2eeb98418d6e740d548d"
};

initializeApp(config);
const messaging = getMessaging();

// 알림 권한 요청
Notification.requestPermission()
  .then((permission) => {
    console.log('permission', permission)
    if (permission === 'granted') {
      console.log('Notification permission granted.');
    } else {
      console.log('Unable to get permission to notify.');
    }
  }).catch(error => {
    alert(error);
  });


// 토큰 가져오기
getToken(messaging, { vapidKey: 'BHh61yOvAGKsFb9gpwQObPAPCnYm0cTbccVtYo7ilo8TqOufSmd90r7Z8RjgZWNNTgpw5_snlHkgqqBUKZGrSBw' })
.then((currentToken) => {
  if (currentToken) {
    // Send the token to your server and update the UI if necessary
    // ...
    window.sessionStorage.setItem('token', currentToken);
  } else {
    // Show permission request UI
    console.log('No registration token available. Request permission to generate one.');
    alert('No registration token available. Request permission to generate one.');

    // ...
  }
}).catch((err) => {
  console.log('An error occurred while retrieving token. ', err);
  alert('An error occurred while retrieving token. -> ' + err);
  // ...
});


onMessage(messaging, (payload) => {
  console.log('Message received. ', payload);
  alert('포그라운드 메시지 -> ' + payload)
  // ...
});